<template>
  <div class="table__container">
    <div class="transaction__filter--container bg-white py-2">
      <div class="d-flex align-items-center search__export w-100">
        <div class="d-flex pl-2">
          <!-- <b-img
            class="filter-image"
            :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
            :src="require('@/assets/images/icons/Icon-order-list-down.svg')"
            alt="Icon-order-list-down"
            role="button"
            @click="
              filter.sort_type == 'asc'
                ? (filter.sort_type = 'desc')
                : (filter.sort_type = 'asc')
            "
          /> -->
        </div>
        <div class="search__input w-100 px-2">
          <b-input-group>
            <b-form-input
              v-model="filter.search"
              placeholder="Cari nama produk"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </div>

    <div
      class="d-flex justify-content-center mb-1 mt-5"
      v-if="isLoading == true"
    >
      <b-spinner label="Loading..." />
    </div>

    <vue-good-table
      v-else
      class="bg-white"
      max-height="67.5vh"
      :columns="columns"
      :rows="rows.data"
      :fixed-header="false"
      :sort-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.field == 'action'" />

        <span v-else-if="props.column.field == 'avg_purchase_price'">
          <span v-if="checkPermission('harga modal')">
            {{ props.column.label }}
          </span>
        </span>

        <span
          v-else-if="props.column.field == 'formatted_id'"
          class="d-flex align-items-center"
          style="gap: 12px; cursor: pointer"
          @click="
            () => {
              filter.sort_by = null;
              filter.sort_type == 'desc'
                ? (filter.sort_type = 'asc')
                : (filter.sort_type = 'desc');
            }
          "
        >
          <span>
            {{ props.column.label }}
          </span>
          <b-img
            v-if="filter.sort_by == null"
            class="filter-image"
            style="width: 12px"
            :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
            :src="require('@/assets/images/icons/Icon-order-list-down.svg')"
            alt="Icon-order-list-down"
            role="button"
          />
        </span>

        <span
          v-else-if="props.column.field == 'alias_name'"
          class="d-flex align-items-center"
          style="gap: 12px; cursor: pointer"
          @click="
            () => {
              filter.sort_by = 'alias_name';
              filter.sort_type == 'desc'
                ? (filter.sort_type = 'asc')
                : (filter.sort_type = 'desc');
            }
          "
        >
          <span>
            {{ props.column.label }}
          </span>
          <b-img
            v-if="filter.sort_by == 'alias_name'"
            class="filter-image"
            style="width: 12px"
            :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
            :src="require('@/assets/images/icons/Icon-order-list-down.svg')"
            alt="Icon-order-list-down"
            role="button"
          />
        </span>

        <span v-else>
          <span>
            {{ props.column.label }}
          </span>
        </span>
      </template>

      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'standard_price'">
          <span>
            {{ props.row.standard_price | formatAmount }}
          </span>
        </span>

        <!-- <span v-else-if="props.column.field === 'sku_code'">
          <span>
            {{ props.row.sku_code || '-' }}
          </span>
        </span> -->
        <span v-else-if="props.column.field === 'warehouse_names'">
          <span>
            {{ props.row.warehouse_names.join(", ") || "-" }}
          </span>
        </span>

        <span v-else-if="props.column.field === 'minimum_price'">
          <span>
            {{ props.row.minimum_price | formatAmount }}
          </span>
        </span>

        <span v-else-if="props.column.field === 'avg_purchase_price'">
          <span v-if="checkPermission('harga modal')">
            {{ props.row.avg_purchase_price | formatAmount }}
          </span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span
            v-if="
              checkPermission('update produk') |
                checkPermission('delete produk')
            "
          >
            <b-dropdown id="dropdown-dropleft" class="d-flex" right>
              <template #button-content class="btn-white text-center">
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <b-dropdown-item
                @click="
                  $router.push({
                    name: 'master-product-eceran.detail',
                    params: { id: props.row.uuid },
                  })
                "
                v-if="checkPermission('update produk')"
              >
                <span> Edit </span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="deleteData(props.row.uuid)"
                v-if="checkPermission('delete produk')"
              >
                <span> Hapus </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <div
      v-if="rows && isLoading == false"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10', '15', '20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan
          {{
            rows.total > 0 ? filter.per_page * (rows.current_page - 1) + 1 : 0
          }}
          ke
          {{
            rows.total > 0
              ? Math.min(filter.per_page * rows.current_page, rows.total)
              : 0
          }}
          dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="rows"
              :limit="4"
              align="right"
              @pagination-change-page="getDataProduct"
            />
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- <ModalIncomeProduct /> -->
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import {
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  VBToggle,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BSpinner,
} from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";
import { mapActions } from "vuex";

import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BRow,
    BCol,
    BSpinner,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "ID",
          field: "formatted_id",
        },
        // {
        //   label: 'SKU',
        //   field: 'sku_code',
        // },
        {
          label: "Nama Alias",
          field: "alias_name",
        },
        {
          label: "Produk Induk",
          field: "parent_product.name",
        },
        {
          label: "Lokasi SKU",
          field: "warehouse_names",
        },
        {
          label: "Nilai Konversi",
          field: "retail_convertion",
        },
        {
          label: "Harga Modal",
          field: "avg_purchase_price",
        },
        {
          label: "Harga Standar",
          field: "standard_price",
        },
        {
          label: "Harga Minimum",
          field: "minimum_price",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: {},
      formPayload: {
        product_type: "",
        image: "",
        item_uuid: "",
        specification: "",
        uom_uuid: "",
        alias_name: "",
        sku_code: 0,
        standard_price: 0,
        minimum_price: 0,
        discount_percents: [],
        discount_fixed: 0,
        discount_start_date: "",
        discount_end_date: "",
        warehouses_uuid: [],
      },
      filter: {
        search: "",
        per_page: 10,
        sort_type: "desc",
        sort_by: null,
      },
      uuid: null,
    };
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getDataProduct();
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getDataProduct();
  },
  methods: {
    ...mapActions("parentProduct", ["getData"]),
    getDataProduct(page = 1) {
      this.isLoading = true;
      const queryParams = this.filter;
      queryParams.page = page;
      queryParams.product_type = "retail";
      this.getData({ params: queryParams, uuid: "" })
        .then((result) => {
          this.rows = result.data.data;
          this.isLoading = false;
          this.uuid = null;
        })
        .catch((err) => {
          if (err.response.data.meta.messages) {
            errorNotification(this, "Oops!", err.response.data.meta.messages);
          }
          this.isLoading = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    clearItem() {
      this.$store.commit("parentProduct/setEditId", null);
      this.$store.commit(
        "parentProduct/setFormPayload",
        (this.formPayload = {
          product_type: "",
          image: "",
          item_uuid: "",
          specification: "",
          uom_uuid: "",
          alias_name: "",
          sku_code: 0,
          standard_price: 0,
          minimum_price: 0,
          discount_percents: [],
          discount_fixed: 0,
          discount_start_date: "",
          discount_end_date: "",
          warehouses_uuid: [],
        })
      );
    },
    detailItem(item) {
      this.$bvModal.show("modal-customer");
      this.isEdit = false;
      this.$store.commit("parentProduct/setResult", item);
    },
    async deleteData(uuid) {
      this.$swal({
        title: "Konfirmasi",
        text: "Apa Anda yakin untuk menghapus produk eceran ini?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("parentProduct/deleteData", `/${uuid}`)
            .then(() => {
              this.getDataProduct();
              this.$swal({
                icon: "success",
                title: "Sukses!",
                text: "Produk induk berhasil dihapus",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
    async editItem(item) {
      await this.clearItem();
      this.uuid = item.uuid;
      this.$store.commit("parentProduct/setEditId", item.uuid);
      this.$store.commit(
        "parentProduct/setFormPayload",
        (this.formPayload = {
          product_type: "parent",
          brand_uuid: item.brand_uuid,
          item_uuid: item.item.uuid,
          specification: item.specification,
          uom_uuid: item.uom.uuid,
          alias_name: item.alias_name,
          sku_code: item.sku_code,
          standard_price: item.standard_price,
          minimum_price: item.minimum_price,
          discount_percents: item.discount_percents.map((o) => o),
          discount_fixed: item.discount_fixed,
          discount_start_date: item.discount_start_date,
          discount_end_date: item.discount_end_date,
          // warehouses_uuid: [],
        })
      );
      this.$bvModal.show("modal-add-product-parent");
    },
  },
};
</script>

<style lang="scss"></style>
